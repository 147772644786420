import React from 'react'
import '../App.css'
import './HeroSection.css'
import { Button } from './Button'
// import videoSrc from '../videos/video-2.mp4'
import heroImgSrc from '../images/vet_with_pets/dog_m3.webp'
import { Link } from 'react-router-dom'

export function HeroSection() {
  return (
    <div className='hero-container'>
        {/* <video src={videoSrc} autoPlay loop muted /> */}
        <div className='hero-text-container'>
            <h1>Professional Online Veterinary Services</h1>
            <p>Experience top-notch 24/7 veterinary care from the comfort of your home. Explore our services for all your pet's health needs. Together, let's make their lives healthier and happier!</p>
            <div className="hero-btns">
                {/* <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
                    GET STARTED
                </Button> */}
                <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large' link='/services'>
                Get Started <i className='far fa=play-circle' />
                </Button>                
            </div>
        </div> 
        
        <div className="image-container">
            <div className="image-wrapper">
                <figure className="image-wrapper-fg">
                    <img src={heroImgSrc} alt="" className='image-wrapper-fg-item' />
                </figure>
            </div>
        </div>
        
    </div>
  )
}

// export default HeroSection