import React from 'react'
import './ContactsHero.css'
import InputField from './InputField'
import contactImageSrc from '../images/vet_with_pets/dog_f3.webp'
import { Button } from './Button'

function ContactsHero() {
  return (
    <>
        <div className="contacts-hero-container">
            <div className="image-container">
                <figure className="image-wrapper">
                    <img src={contactImageSrc} alt="" className='image-wrapper-item'/>
                </figure>
            </div>
            <div className="inputs-container">
                <div className="inputs-banner">
                    <h1>Email Support</h1>
                    <p>Please complete the form below and a we will reply soon, typically within 24 hours. Your email address will only be used for account look-up and communication purposes. Please enter the same email used for registration so we may better assist you. Besides you can e-mail to support@tigahospital.com directly.</p>
                </div>
                <div className="inputs-textfields">
                    <InputField 
                        type='name'
                        name='Name'
                        placeholder='Full Name'
                        className='inputs-textfield-name'
                    />
                    <InputField 
                        type='email'
                        name='Email'
                        placeholder='Email address'
                        className='inputs-textfield-email'
                    />
                    <InputField 
                        type='message'
                        name='Message'
                        placeholder='How can we get better'
                        textfieldStyle='textarea'
                        className='inputs-textfield-message'
                    />
                </div>
                <div className="inputs-submitbtn">
                    <Button buttonStyle='btn--primary'>Send message</Button>
                </div>
            </div>
        </div>  
    </>
  )
}

export default ContactsHero