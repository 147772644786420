import React from 'react'
import './InputField.css'

const STYLES = ['textfield', 'textarea']

function InputField(props) {
    const { type, name, placeholder, textfieldStyle } = props

    const _textfieldStyle = STYLES.includes(textfieldStyle) ? textfieldStyle : STYLES[0];
  return (
    <>
        <div className="input-field-container">
            <div className="input-field-title">
                <p>{name}</p>
            </div>
            <div className="input-field-wrapper">
                { 
                (_textfieldStyle === 'textfield') ?
                <input type={type} name={name} placeholder={placeholder} className="input-field-item"/> :
                <textarea type={type} name={name} placeholder={placeholder} className="input-field-item"
                    rows='4'
                />
                }
            </div>
        </div>   
    </>
  )
}

export default InputField