import React from 'react'
import './ImageView.css'

const STYLES = ['imageview--half', 'imageview--full'];

function ImageView(props) {
    const {src, alt, imageViewStyle} = props
    const style = STYLES.includes(imageViewStyle) ? imageViewStyle : STYLES[0]
  return (
    <>
        <div className="image-view">
                <figure className="image-wrapper">
                    <img src={src} alt={alt} className={`image-wrapper-item ${style}`}/>
                </figure>
            </div>
    </>
  )
}

export default ImageView